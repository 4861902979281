'use client';
import { useRef } from 'react';

import GlassWrapper from '~/components/atoms/GlassWrapper/GlassWrapper';
import Media from '~/components/molecules/Media/Media';
import PlayableVideo from '~/components/molecules/PlayableVideo/PlayableVideo';
import Shadow from '~/components/molecules/Shadow/Shadow';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import { cn } from '~/utils';

import styles from './StandaloneMedia.module.css';
import { StandaloneMediaProps } from './StandaloneMedia.types';

const StandaloneMedia = (props: StandaloneMediaProps) => {
  const {
    standaloneMediaType = 'backgroundMedia',
    backgroundMedia,
    playableVideo,
    mobileAlignment,
    displayGlassBorder,
  } = props;
  const $mediaContainer = useRef<HTMLDivElement>(null);
  const Wrapper = displayGlassBorder ? GlassWrapper : 'div';

  const renderBgMedia = () => {
    return (
      <Media
        className={styles.media}
        sanityMedia={backgroundMedia?.media.sanityMedia}
      />
    );
  };

  const renderPlayableVideo = () => {
    return (
      <PlayableVideo
        {...playableVideo}
        src={playableVideo.url}
        className={styles.media}
        displayPreview={true}
      />
    );
  };

  return (
    <ModuleWrapper
      className={cn(
        styles.standaloneMedia,
        mobileAlignment === 'right' && styles.isMobileRightAligned,
        mobileAlignment === 'left' && styles.isMobileLeftAligned,
      )}
      {...props}
    >
      <div
        className={cn(
          styles.mediaWrapper,
          mobileAlignment === 'right' && styles.mediaWrapperMobileRightAligned,
          mobileAlignment === 'left' && styles.mediaWrapperMobileLeftAligned,
          mobileAlignment === 'center' &&
            styles.mediaWrapperMobileCenterAligned,
        )}
      >
        <Shadow>
          <Wrapper ref={$mediaContainer} className={styles.mediaContainer}>
            {standaloneMediaType === 'backgroundMedia'
              ? renderBgMedia()
              : renderPlayableVideo()}
          </Wrapper>
        </Shadow>
      </div>
    </ModuleWrapper>
  );
};

export default StandaloneMedia;
