'use client';
import { gsap } from 'gsap';
import { capitalize } from 'lodash';
import { CSSProperties, useCallback, useRef } from 'react';

import { VideoRef } from '~/components/atoms/Video/Video.types';
import Media from '~/components/molecules/Media/Media';
import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import {
  cn,
  useIsomorphicLayoutEffect as useLayoutEffect,
  useScrollProgress,
} from '~/utils';

import styles from './PotholeV4.module.css';
import { PotholeProps } from './PotholeV4.types';

/**
 * Pothole module
 * @param content PortableText content
 * @param media Image data coming from Sanity, used in the background of the component
 * @param media Image data coming from Sanity, used in the background of the component
 * @param overflow Boolean value to determine if the content should overflow the container
 * @param verticalAlignment 'top' | 'center' | 'bottom', determines the vertical alignment of the content
 * @param glowBackground Define the 'background' CSS property for the 'glow', typically a gradient
 */
const Pothole = (props: PotholeProps) => {
  const {
    verticalAlignment,
    overflow,
    className,
    content,
    isFirstModule,
    isLastModule,
    media,
    mediaLayout = 'fullWidth',
    mediaVerticalAlignment,
    mediaScale,
    glowBackground,
  } = props;

  const mediaRef = useRef<HTMLElement | VideoRef>(null);

  const $element = useRef<HTMLDivElement>(null);
  const progressSetter = useRef<(value: number) => void>();

  useLayoutEffect(() => {
    if (typeof $element.current !== 'undefined') {
      progressSetter.current = gsap.quickSetter(
        $element.current,
        '--pothole-progress',
      ) as (value: number) => void;
    }
  }, []);

  const onProgress = useCallback((progress: number, isInView: boolean) => {
    if (media.media.sanityMedia?.mediaType === 'video') {
      if (mediaRef && mediaRef.current && 'pause' in mediaRef.current) {
        if (!isInView) {
          mediaRef.current.restart(false);
        } else {
          mediaRef.current.play();
        }
      }
    }

    if (isInView) {
      if (progressSetter.current) {
        progressSetter.current(progress);
      }
    }
  }, []);

  useScrollProgress($element, onProgress);

  return (
    <ModuleWrapper
      className={cn(
        styles.potholeV4,
        isFirstModule && styles.isFirstModule,
        isLastModule && styles.isLastModule,
        styles[`${verticalAlignment}Aligned`],
        styles[`mediaAlign${capitalize(mediaVerticalAlignment)}`],
        className,
      )}
      ref={$element}
      {...props}
    >
      <div className={styles.backgroundGrid}>
        <div
          className={cn(
            styles.backgroundContainer,
            styles[mediaLayout],
            overflow && styles.overflow,
          )}
        >
          <div
            className={styles.backgroundOverlay}
            style={
              {
                '--media-scale': mediaScale !== undefined ? mediaScale : 1,
              } as CSSProperties
            }
          >
            <Media
              ref={mediaRef}
              sanityMedia={media.media.sanityMedia}
              className={styles.backgroundMedia}
              willAutoplay={false}
              forceUseMp4
              ariaHidden={true}
              imageColumns={
                media.media.sanityMedia?.mediaType === 'video' ? 3 : 12
              }
            />
            <div
              className={styles.potholeGlow}
              style={
                {
                  ...(glowBackground
                    ? { '--glow-background': glowBackground }
                    : {}),
                } as CSSProperties
              }
            />
          </div>
        </div>
      </div>

      <TextLockup
        className={styles.content}
        value={content.blocks}
        lockupOptions={content.lockupOptions}
      />
    </ModuleWrapper>
  );
};

export default Pothole;
