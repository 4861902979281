'use client';
import { gsap } from 'gsap';
import { KeenSliderOptions, useKeenSlider } from 'keen-slider/react';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useRef, useState } from 'react';

import ButtonCarouselArrow from '~/components/atoms/Buttons/UI/ButtonCarouselArrow/ButtonCarouselArrow';
import GlassWrapper from '~/components/atoms/GlassWrapper/GlassWrapper';
import Image from '~/components/atoms/Image/Image';
import Media from '~/components/molecules/Media/Media';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import UIStore from '~/state/ui';
import globalStyles from '~/styles/global.module.css';
import blurredImagesStyles from '~/styles/theme/blurredImages.module.css';
import { cn, getSpacer } from '~/utils';
import calculateRelativeInstanceDistance from '~/utils/calculateRelativeInstanceDistance';
import calculateSlideVisibility from '~/utils/calculateSlideVisibility';
import getKeenSliderSlidesPerView from '~/utils/getKeenSliderSlidesPerView';
import keenSliderConfig from '~/utils/keenSliderConfig';
import Easing, { EaseType } from '~/utils/singletons/Easing';

import styles from './CarouselMedia.module.css';
import { CarouselMediaProps } from './CarouselMedia.types';
import positionSlidesOnMobile from './utils/positionSlidesOnMobile';

const CarouselMedia = (props: CarouselMediaProps) => {
  const { slides, className } = props;

  const $element = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const $slides = useRef<HTMLDivElement[]>(Array(slides.length));
  const $blurs = useRef<HTMLDivElement[]>(Array(slides.length));
  const $overlays = useRef<HTMLDivElement[]>(Array(slides.length));
  const $blurDim = useRef<HTMLDivElement>(null);

  const slideAspectRatios = useRef<number[]>(Array(slides.length));

  const $dummyGrid = useRef<HTMLDivElement>(null);
  const $marginSizer = useRef<HTMLDivElement>(null);

  const mobileCurSlide = useRef(-1);

  const getSliderOptions: () => KeenSliderOptions = useCallback(() => {
    // Default spacer
    const marginSizerBox = $marginSizer.current?.getBoundingClientRect();
    const spacer = marginSizerBox?.width || getSpacer(64);

    // config for sm breakpoint
    const mobileSlideConfig: KeenSliderOptions = {
      renderMode: 'custom',
      loop: false,
      slides: {
        spacing: 0,
        origin: 0,
        perView: 1,
      },
      created: () => {
        showBlurredImageOnMobile(0);
      },
      detailsChanged: (instance) => {
        positionSlidesOnMobile(instance, $slides, $overlays);

        const { abs } = instance.track.details;

        showBlurredImageOnMobile(abs);
      },
    };

    const showBlurredImageOnMobile = (newIndex: number) => {
      if (mobileCurSlide.current !== newIndex) {
        gsap.set($blurs.current[mobileCurSlide.current], {
          opacity: 0,
        });

        gsap.set($blurs.current[newIndex], {
          opacity: 1,
        });

        gsap.set($blurDim.current, {
          '--aspect-ratio': slideAspectRatios.current[newIndex],
        });
        mobileCurSlide.current = newIndex;
      }
    };

    // config for md and lg breakpoints
    const slideConfig: KeenSliderOptions = {
      loop: true,
      slides: {
        spacing: spacer,
        origin: 'center',
        // using the window width and grid width, calculate how many slides are visible on the screen if the active slide is set to the grid width
        perView: getKeenSliderSlidesPerView($dummyGrid, spacer),
      },

      detailsChanged: (instance) => {
        const progress = instance.track.details.progress;
        const progresses = calculateSlideVisibility(
          slides.length,
          progress,
          true,
        );

        for (
          let slideIndex = 0;
          slideIndex < instance.track.details.slides.length;
          slideIndex++
        ) {
          if (progresses[slideIndex] > 0) {
            const filterValue = gsap.utils.mapRange(
              0,
              1,
              0.5,
              1,
              progresses[slideIndex],
            );

            gsap.set($blurs.current[slideIndex], {
              visibility: 'visible',
              opacity: progresses[slideIndex],
            });
            gsap.set($slides.current[slideIndex], {
              filter: `saturate(${filterValue}) brightness(${filterValue})`,
            });
          }
        }
      },
    };

    return {
      ...keenSliderConfig.defaultConfig,
      breakpoints: {
        [keenSliderConfig.breakpoints.sm.mediaQuery]: {
          ...mobileSlideConfig,
        },
        [keenSliderConfig.breakpoints.md.mediaQuery]: {
          ...slideConfig,
        },
      },
      defaultAnimation: {
        duration: 1100,
        easing: Easing.getEasingFunction(EaseType.EMBELLISHMENT),
      },
      animationEnded: (instance) => {
        for (
          let slideIndex = 0;
          slideIndex < instance.track.details.slides.length;
          slideIndex++
        ) {
          if (
            slideIndex !== instance.track.details.rel &&
            UIStore.getState().breakpoint?.name !== 'sm'
          ) {
            gsap.set($blurs.current[slideIndex], {
              visibility: 'hidden',
            });
          }
        }
        checkCurrentSlide.current();
      },
      selector: $slides.current,
    };
  }, [slides.length]);

  const baseSliderOptions = useRef(getSliderOptions());

  const [$sliderRef, sliderInstance] = useKeenSlider(baseSliderOptions.current);

  const checkCurrentSlide = useRef(() => {
    const keenSlider = sliderInstance.current;
    const currentIndex = keenSlider?.track.details?.rel;

    setActiveIndex(currentIndex || 0);
  });
  // on window resize, call slider update function to resize slides per view
  const updateSlider = useCallback(() => {
    sliderInstance.current?.update(getSliderOptions());

    if ($dummyGrid.current && UIStore.getState().breakpoint?.name === 'sm') {
      const gridMarginSize =
        ((UIStore.getState().windowWidth || 0) -
          $dummyGrid.current?.getBoundingClientRect().width) /
        2;

      // used to position the blur dim flush against the viewport in mobile
      gsap.set($blurDim.current, {
        '--grid-margin-size': `${gridMarginSize}px`,
      });
    }
  }, [sliderInstance, getSliderOptions]);

  useEffect(() => {
    checkCurrentSlide.current();

    const unsubscribe = UIStore.subscribe(
      (state) => [state.windowWidth as number],
      debounce(updateSlider, 100),
    );

    return () => {
      unsubscribe();
    };
  }, [updateSlider]);

  useEffect(() => {
    $slides.current.forEach((slide, i) => {
      const z = $slides.current.length - 1 - i;
      gsap.set(slide, {
        '--slide-z-index': z,
      });
    });
  }, []);

  useEffect(() => {
    slideAspectRatios.current = slides.map((slide) => {
      let aspectRatio;

      switch (slide.media.sanityMedia.mediaType) {
        case 'image':
          aspectRatio = slide.media.sanityMedia.asset.aspectRatio;
          break;
        case 'video':
          aspectRatio = slide.media.sanityMedia.thumbnail?.asset.aspectRatio;
          break;
      }
      return aspectRatio || 1;
    });
  }, [slides]);

  const scrollPrev = useCallback(() => {
    sliderInstance.current?.prev();
  }, [sliderInstance]);

  const scrollNext = useCallback(() => {
    sliderInstance.current?.next();
  }, [sliderInstance]);

  return (
    <ModuleWrapper
      ref={$element}
      {...props}
      className={cn(styles.carouselMedia, className)}
    >
      {/* used to calculate the grid width regardless of screen size, to set the active slide's width */}
      <div className={styles.dummyGrid} ref={$dummyGrid} />
      <div className={styles.marginSizer} ref={$marginSizer} />
      <div className={styles.slider}>
        {slides && (
          <div className={blurredImagesStyles.blurs}>
            {slides.map((slide, i) => {
              let blur;

              switch (slide.media.sanityMedia.mediaType) {
                case 'image':
                  blur = slide.media.sanityMedia;
                  break;
                case 'video':
                  blur = slide.media.sanityMedia.thumbnail;
                  break;
              }

              return (
                <div
                  className={blurredImagesStyles.blurWrapper}
                  key={slide._key}
                  ref={(node) => {
                    if (node) {
                      $blurs.current[i] = node;
                    }
                  }}
                  style={{
                    aspectRatio: slideAspectRatios.current[i],
                  }}
                >
                  {blur && (
                    <Image
                      source={blur}
                      className={blurredImagesStyles.blur}
                      dpr={1}
                      blur={300}
                      quality={100}
                    />
                  )}
                </div>
              );
            })}
            <div className={blurredImagesStyles.blurDim} ref={$blurDim} />
          </div>
        )}
        {slides && (
          <div className={styles.slides} ref={$sliderRef}>
            {slides.map((slide, i) => {
              const relativeDistance = calculateRelativeInstanceDistance(
                slides.length,
                i,
                activeIndex,
                UIStore.getState().breakpoint?.name !== 'sm',
              );
              return (
                <div
                  className={styles.slide}
                  key={slide._key}
                  ref={(node) => {
                    if (node) {
                      $slides.current[i] = node;
                    }
                  }}
                >
                  <div
                    className={styles.overlay}
                    ref={(node) => {
                      if (node) {
                        $overlays.current[i] = node;
                      }
                    }}
                  />
                  <GlassWrapper>
                    <Media
                      className={styles.media}
                      sanityMedia={slide.media.sanityMedia}
                      forceIsInView={activeIndex === i}
                      imageColumns={{
                        md: 9,
                        sm: 12,
                      }}
                      preload={relativeDistance <= 2}
                      imageAnimated={false}
                    />
                  </GlassWrapper>
                </div>
              );
            })}
          </div>
        )}

        <div
          className={cn(
            globalStyles.carouselArrowsContainer,
            styles.carouselArrowsContainer,
          )}
        >
          <ButtonCarouselArrow
            className={cn(
              globalStyles.carouselArrowsButtonPrev,
              styles.carouselArrowsButtonPrev,
              activeIndex === 0 &&
                !sliderInstance.current?.options.loop &&
                globalStyles.carouselArrowsButtonDisabled,
            )}
            iconDirection={'left'}
            onClick={scrollPrev}
          />
          <ButtonCarouselArrow
            className={cn(
              globalStyles.carouselArrowsButtonNext,
              styles.carouselArrowsButtonNext,
              activeIndex === slides.length - 1 &&
                !sliderInstance.current?.options.loop &&
                globalStyles.carouselArrowsButtonDisabled,
            )}
            iconDirection={'right'}
            onClick={scrollNext}
          />
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default CarouselMedia;
