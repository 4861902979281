'use client';

import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import { InertiaPlugin } from 'gsap/InertiaPlugin';
import { debounce } from 'lodash';
import { CSSProperties, useCallback, useEffect, useRef } from 'react';

import GlassWrapper from '~/components/atoms/GlassWrapper/GlassWrapper';
import Media from '~/components/molecules/Media/Media';
import useUIStore from '~/state/ui';
import { cn } from '~/utils';

import styles from './OverflowingMedia.module.css';
import { OverflowingMediaProps } from './OverflowingMedia.types';
gsap.registerPlugin(InertiaPlugin);
gsap.registerPlugin(Draggable);

const OverflowingMedia = ({
  media,
  alignment = 'alignLeft',
  className,
}: OverflowingMediaProps) => {
  const $container = useRef<HTMLDivElement>(null);
  const $media = useRef<HTMLDivElement>(null);
  const $scrubberBar = useRef<HTMLDivElement>(null);
  const $scrubber = useRef<HTMLDivElement>(null);
  const draggableInstance = useRef<Draggable>();
  const scrubberMaxX = useRef<number>();

  const alignScrubber = () => {
    if (draggableInstance.current && scrubberMaxX.current) {
      const { minX, x } = draggableInstance.current;
      const pos = x / minX;
      gsap.set($scrubber.current, {
        x: scrubberMaxX.current * pos,
      });
    }
  };

  const setInitialState = useCallback(() => {
    const scrubberBarWidth =
      $scrubberBar.current?.getBoundingClientRect().width || 0;
    const scrubberWidth = $scrubber.current?.getBoundingClientRect().width || 0;
    scrubberMaxX.current = scrubberBarWidth - scrubberWidth;
    // if initial state is align right, set the container to the max X position
    if (alignment === 'alignRight') {
      gsap.set($media.current, {
        x: draggableInstance.current?.minX,
      });
      gsap.set($scrubber.current, {
        x: scrubberMaxX.current,
      });
    }
  }, [alignment]);

  useEffect(() => {
    const unsubscribe = useUIStore.subscribe(
      (state) => state.windowWidth,
      debounce(() => {
        setInitialState();
      }, 100),
    );

    return () => {
      unsubscribe();
    };
  }, [setInitialState]);

  useEffect(() => {
    draggableInstance.current = Draggable.create($media.current, {
      bounds: $container.current,
      type: 'x',
      inertia: true,
      edgeResistance: 0.8,
      onDrag: alignScrubber,
      onThrowUpdate: alignScrubber,
    })[0];

    setInitialState();
  }, [setInitialState]);

  const mediaAspectRatio =
    'asset' in media.sanityMedia
      ? media.sanityMedia.asset.aspectRatio
      : media.sanityMedia.aspectRatio;
  return (
    <>
      <div className={cn(styles.overflowingMedia, className)} ref={$container}>
        <GlassWrapper
          ref={$media}
          className={styles.mediaWrapper}
          style={
            {
              ...(mediaAspectRatio
                ? { '--media-aspect-ratio': mediaAspectRatio }
                : {}),
            } as CSSProperties
          }
        >
          <Media
            sanityMedia={media.sanityMedia}
            className={styles.media}
            willAutoplay={true}
          />
        </GlassWrapper>
      </div>
      <div className={styles.scrubberBar} ref={$scrubberBar}>
        <span className={styles.scrubber} ref={$scrubber}></span>
      </div>
    </>
  );
};

export default OverflowingMedia;
