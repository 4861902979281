'use client';

import { capitalize } from 'lodash';
import { CSSProperties, useMemo } from 'react';

import GlassWrapper from '~/components/atoms/GlassWrapper/GlassWrapper';
import { CMSImage, ImageColumns } from '~/components/atoms/Image/Image.types';
import Media from '~/components/molecules/Media/Media';
import Shadow from '~/components/molecules/Shadow/Shadow';
import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import { cn } from '~/utils';

import styles from './FeatureCard.module.css';
import { FeatureCardProps } from './FeatureCard.types';

const FeatureCard = ({
  content,
  background,
  foreground,
  textPlacement,
  textAlignment,
  bentoMediaLayout,
  row,
  bentoSize,
  bentoRowPosition,
  column,
}: FeatureCardProps) => {
  const ForegroundWrapper =
    foreground && foreground.decoration === 'glassborder'
      ? GlassWrapper
      : 'div';
  const ShadowWrapper =
    foreground && foreground.decoration !== 'none' ? Shadow : 'div';

  const foregroundAspectRatio = foreground
    ? foreground.media.media.sanityMedia.mediaType === 'image'
      ? foreground.media.media.sanityMedia.asset.aspectRatio
      : foreground.media.media.sanityMedia.thumbnail?.asset.aspectRatio
    : 1;
  const hotspotIsAtTop = useMemo(() => {
    const sanityMedia = background?.media?.media?.sanityMedia as CMSImage;
    if (!sanityMedia?.hotspot) return false;
    const hotspotHeight = sanityMedia.hotspot.height;
    const hotspotY = sanityMedia.hotspot.y;
    const totalFromTop = hotspotHeight + hotspotY;
    const threshold = 0.5;
    return totalFromTop < threshold;
  }, [background]);

  return (
    <div
      className={cn(
        styles.bentoCard,
        styles[`bentoSize${bentoSize}`],
        styles[`bentoRowPosition${bentoRowPosition}`],
        styles[`textPlacement${capitalize(textPlacement)}`],
        styles[`textAlignment${capitalize(textAlignment)}`],
        row > 1 && styles.multipleRow,
        background &&
          background.mediaDisplayMode === 'cover' &&
          styles.isCoverBackground,
      )}
    >
      <TextLockup
        value={content.blocks}
        lockupOptions={content.lockupOptions}
        className={styles.content}
      />
      <div
        className={cn(
          styles.mediaWrapper,
          styles[bentoMediaLayout],
          background && styles[background.mediaDisplayMode],
          foreground &&
            bentoMediaLayout === 'foregroundOnly' &&
            styles[`foreground${capitalize(foreground.mediaSize)}`],
          bentoMediaLayout === 'foregroundOnly' &&
            foreground.mediaSize === 'bleed' &&
            styles[`foregroundBleed${capitalize(foreground.bleedSize)}`],
        )}
      >
        {background && (
          <div
            className={cn(
              styles.backgroundWrapper,
              (background.mediaDisplayMode === 'fullWidthWithShadows' ||
                background.mediaDisplayMode === 'fullWidth') &&
                background.size &&
                styles[`backgroundSize${capitalize(background.size)}`],
            )}
            data-display-mode={background.mediaDisplayMode}
            data-size={background.size}
          >
            <Media
              sanityMedia={background.media.media.sanityMedia}
              className={cn(
                styles.backgroundMedia,
                styles[background.mediaDisplayMode],
                hotspotIsAtTop && styles.hotspotIsAtTop,
              )}
              imageColumns={{
                md: column as ImageColumns,
                sm: '100vw',
              }}
            />
          </div>
        )}
        {foreground && (
          <div
            className={cn(styles.foregroundContainer)}
            style={
              {
                '--foreground-aspect-ratio': foregroundAspectRatio,
              } as CSSProperties
            }
          >
            <ShadowWrapper>
              <ForegroundWrapper
                className={
                  foreground && foreground.decoration !== 'glassborder'
                    ? styles.noBorder
                    : styles.withBorder
                }
              >
                <Media
                  sanityMedia={foreground.media.media.sanityMedia}
                  className={styles.foregroundMedia}
                  imageColumns={{
                    md: column as ImageColumns,
                    sm: 12,
                  }}
                />
              </ForegroundWrapper>
            </ShadowWrapper>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeatureCard;
