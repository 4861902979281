'use client';

import { gsap } from 'gsap';
import { useEffect, useRef, useState } from 'react';

import Button from '~/components/atoms/Buttons/Ctas/Button/Button';
import Image from '~/components/atoms/Image/Image';
import PortableText from '~/components/molecules/PortableText/PortableText';
import { all } from '~/components/molecules/TextLockups/TextLockups.config';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import { dict } from '~/data/stores/Dictionary';
import UIStore from '~/state/ui';
import { cn } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

import { LivestreamEvent } from '../Live.types';
import {
  getDisplayDate,
  getDisplayDateAndTime,
  PortableTextUtil,
} from '../Live.utils';
import { useSwipeControls } from './hooks/useSwipeControls';
import styles from './LiveSchedule.module.css';
import { LiveScheduleProps } from './LiveSchedule.types';
import { findNextClosestEvent, sortEventsByDate } from './LiveSchedule.utils';

const LiveSchedule = (props: LiveScheduleProps) => {
  const schedule = props.schedule.sort(sortEventsByDate);
  const now = new Date();
  const closest = findNextClosestEvent(schedule, now);

  if (!closest) {
    throw new Error('Schedule contains no events');
  }

  const [isNavOverflowing, setIsNavOverflowing] = useState(true);
  const $nav = useRef<HTMLElement>(null);
  const $navList = useRef<HTMLOListElement>(null);
  const $navItems = useRef<HTMLLIElement[]>([]);
  const [current, setCurrent] = useState<LivestreamEvent>(closest);

  // manage the scroll/swipe interaction on the schedule list
  useSwipeControls($navList);

  useEffect(() => {
    if (
      !Array.isArray($navItems.current) ||
      $navItems.current.length === 0 ||
      !$navList.current
    ) {
      return;
    }

    // remove the selected class from the previous item
    $navItems.current.forEach((el) => {
      if (el.classList.contains(styles.liveScheduleSelectedEventButton)) {
        el.classList.remove(styles.liveScheduleSelectedEventButton);
      }
    });

    // mark current as selected and center into view if possible
    const item = $navItems.current.find((el) => {
      return el.dataset.slug === current.slug;
    });

    if (!item) {
      return;
    }

    item.classList.add(styles.liveScheduleSelectedEventButton);

    const container = $navList.current.getBoundingClientRect();
    const target = item.getBoundingClientRect();
    const index = parseInt(item.dataset.index as string, 10) as number;

    gsap.killTweensOf($navList.current);
    gsap.to($navList.current, {
      scrollLeft:
        -target.width * index + (container.width / 2 - target.width / 2),
      duration: 0.2,
      ease: 'power3',
    });
  }, [$navList, $navItems, current]);

  // When the window resizes, check to see if the navigation is overflowing.
  useEffect(() => {
    function updateNavState() {
      const container = $nav.current;
      const list = $navList.current;
      const items = $navItems.current;

      if (!container || !list || !Array.isArray(items) || items.length === 0) {
        return;
      }

      const containerRect = container.getBoundingClientRect();
      const listItemRect = items[0].getBoundingClientRect();

      setIsNavOverflowing(
        listItemRect.width * schedule.length > containerRect.width,
      );
    }

    updateNavState();

    return UIStore.subscribe((state) => state.windowWidth, updateNavState);
  }, [$nav, $navList, schedule.length]);

  const allTextLockup = all();

  const { content, title, body } = PortableTextUtil;

  const getEventCTA = (event: LivestreamEvent) => {
    if (new Date(event.date) < new Date(Date.now())) {
      return (
        <Button
          to={{ url: `/${event.slug}`, type: 'internal' }}
          className={styles.eventCta}
          buttonColorScheme="ghost"
        >
          {dict('fioLiveStreamWatchCTALabel')}
        </Button>
      );
    }
    return (
      <Button
        to={{ url: `/${event.slug}/register`, type: 'internal' }}
        className={styles.eventCta}
        buttonVariant="primary"
        buttonColorScheme="ghost"
      >
        {dict('fioLiveStreamRegisterCTALabel')}
      </Button>
    );
  };

  return (
    <ModuleWrapper className={styles.liveScheduleModule} {...props}>
      <div className={styles.liveScheduleContainer}>
        <div className={styles.liveScheduleContent}>
          <nav
            ref={$nav}
            className={cn(
              styles.liveScheduleNavigation,
              isNavOverflowing ? styles.liveScheduleNavigationOverflow : null,
            )}
          >
            <ol ref={$navList}>
              {schedule.map((event, idx) => {
                return (
                  <li
                    key={event.slug}
                    data-slug={event.slug}
                    data-index={idx}
                    className={cn(
                      styles.liveScheduleEventButton,
                      current.slug === event.slug
                        ? styles.liveScheduleSelectedEventButton
                        : '',
                    )}
                    ref={(el) =>
                      addToRefArray({ element: el, refArray: $navItems })
                    }
                  >
                    <button onClick={() => setCurrent(event)}>
                      {getDisplayDate(event)}
                    </button>
                  </li>
                );
              })}
            </ol>
          </nav>
          <div className={styles.liveScheduleEventDetails}>
            <PortableText
              className={cn(
                styles.eventTitlePortableText,
                styles.nonGridPortableText,
              )}
              value={content(
                title('title4', current.title),
                title('title4', getDisplayDateAndTime(current), ['em']),
                body(current.description, []),
              )}
              options={allTextLockup.options}
            />
            {getEventCTA(current)}
            <div className={styles.speakersListWrapper}>
              {current.speakers?.length > 0 ? (
                <ul className={styles.speakersList}>
                  {current.speakers?.map((speaker, index) => {
                    return (
                      <li
                        key={`${speaker._id}_${index}`}
                        className={styles.speakerItem}
                      >
                        <div className={styles.speaker}>
                          <div className={styles.speakerAvatar}>
                            <Image
                              width={100}
                              height={100}
                              preload={true}
                              source={speaker.avatar}
                            />
                          </div>
                          <div className={styles.speakerDetails}>
                            <PortableText
                              className={cn(styles.nonGridPortableText)}
                              value={content(
                                title('title5', speaker.fullName),
                                body(`${speaker.title}, ${speaker.company}`),
                              )}
                              options={allTextLockup.options}
                            />
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default LiveSchedule;
