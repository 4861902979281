'use client';
import { gsap } from 'gsap';
import { useCallback, useRef } from 'react';

import EnhancedMedia from '~/components/molecules/EnhancedMedia/EnhancedMedia';
import Media from '~/components/molecules/Media/Media';
import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import {
  cn,
  useIsomorphicLayoutEffect as useLayoutEffect,
  useScrollProgress,
} from '~/utils';

import styles from './Pothole.module.css';
import { PotholeProps } from './Pothole.types';

/**
 * Pothole module
 * @param content PortableText content
 * @param image Image data coming from Sanity, used in the background of the component
 * @param layout POTHOLE_LAYOUT, will determine which sub-component to use to render the content properly
 * @param logo SVG content coming from Sanity, used only on certain layouts
 * @param characterCount The character count of the main block in content, used to determine the variant used in certain layouts
 * @param gradientOverlay String value for the name of gradient overlay that sits on top of the background image

*/
const Pothole = (props: PotholeProps) => {
  const { className, content, image } = props;

  const $element = useRef<HTMLDivElement>(null);
  const progressSetter = useRef<(value: number) => void>();

  useLayoutEffect(() => {
    if (typeof $element.current !== 'undefined') {
      progressSetter.current = gsap.quickSetter(
        $element.current,
        '--pothole-progress',
      ) as (value: number) => void;
    }
  }, []);

  const onProgress = useCallback((progress: number, isInView: boolean) => {
    if (isInView) {
      if (progressSetter.current) {
        progressSetter.current(progress);
      }
    }
  }, []);

  useScrollProgress($element, onProgress);

  return (
    <ModuleWrapper
      className={cn(styles.pothole, className)}
      ref={$element}
      {...props}
    >
      <div className={styles.backgroundContainer}>
        <EnhancedMedia
          className={styles.backgroundOverlay}
          overlay={image.overlay}
          overlayClassName={styles.overlay}
        >
          <Media
            sanityMedia={image.image}
            className={styles.background}
            imageAspectRatio={{
              md: 1.75,
              sm: 0.5,
            }}
          />
        </EnhancedMedia>
      </div>
      <div className={styles.contentContainer}>
        <TextLockup
          className={styles.content}
          value={content.blocks}
          lockupOptions={content.lockupOptions}
        />
      </div>
    </ModuleWrapper>
  );
};

export default Pothole;
