'use client';

import { useKeenSlider } from 'keen-slider/react';
import { useCallback, useEffect, useRef, useState } from 'react';

import Button from '~/components/atoms/Buttons/Ctas/Button/Button';
import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import usePricingStore from '~/components/organisms/modules/Pricing/Pricing.store';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import useUIStore from '~/state/ui';
import { cn, keenSliderConfig } from '~/utils';
import ClientOnlyPortal from '~/utils/ClientOnlyPortal/ClientOnlyPortal';
import ScrollPosition from '~/utils/domEvents/ScrollPosition/ScrollPosition';
import getRootOffsetBounds from '~/utils/getRootOffsetBounds';
import { tickerAdd, tickerRemove } from '~/utils/ticker';

import { CMSPriceDetails } from '../Pricing.types';
import PricingCategory from './PricingCategory/PricingCategory';
import PricingSlider from './PricingSlider';
import styles from './PricingTable.module.css';
import { PricingTableProps } from './PricingTable.types';

const PricingTable = (props: PricingTableProps) => {
  const { pricing, text, disclaimer } = props;
  const { products, pricingFeatures: pricingCategories } = pricing;

  const globalPricePeriodValue = usePricingStore((state) => {
    const storedPeriod = state.pricePeriod;
    if (storedPeriod === null) {
      return pricing.defaultPricePeriod.value;
    }
    return storedPeriod;
  });

  const setSelectedPlan = usePricingStore((state) => state.setSelectedPlan);
  const selectedPlan = usePricingStore((state) => state.selectedPlan);
  const initialSelectedPlan = useRef(1);
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(1);

  const renderPrice = (pricesByPeriod: CMSPriceDetails[]) => {
    const activePeriod = pricesByPeriod.find(
      ({ pricePeriod }) => pricePeriod.value === globalPricePeriodValue,
    );

    // nb price period omitted for enterprise
    if (activePeriod?.price === null) {
      return 'Custom';
    }

    return `$${activePeriod?.price}/${activePeriod?.pricePeriod.abbreviation}`;
  };

  const getPlanCtaProps = useCallback(
    (pricesByPeriod: CMSPriceDetails[]) => {
      const activePeriod = pricesByPeriod?.find(
        ({ pricePeriod }) => pricePeriod.value === globalPricePeriodValue,
      );

      return activePeriod?.signUpButton.button;
    },
    [globalPricePeriodValue],
  );

  const $refEl = useRef<HTMLDivElement>(null);
  const $refHeadingsRow = useRef<HTMLDivElement>(null);
  const $refTable = useRef<HTMLTableElement>(null);
  const $refTableBodyWrapper = useRef<HTMLDivElement>(null);
  const $refTableHeadersWrapper = useRef<HTMLDivElement>(null);

  const [tableBounds, setTableBounds] = useState(
    $refTable.current ? getRootOffsetBounds($refTable.current) : null,
  );

  const [headingsBounds, setHeadingsBounds] = useState(
    $refHeadingsRow.current
      ? getRootOffsetBounds($refHeadingsRow.current)
      : null,
  );

  const [ctaHidden, setCtaHidden] = useState(false);
  const [isStuck, setIsStuck] = useState(false);

  // ui store values

  const navigationHeight = useUIStore((state) => state.navigationHeight || 80);
  const windowWidth = useUIStore((state) => state.windowWidth);
  const windowHeight = useUIStore((state) => state.windowHeight);
  const breakpoint = useUIStore((state) => state.breakpoint);
  const isMobile = breakpoint?.name === 'sm';

  const setIsDesktopNavigationOpaque = useUIStore(
    (state) => state.setIsDesktopNavigationOpaque,
  );

  const unsetIsDesktopNavigationOpaque = useUIStore(
    (state) => state.unsetIsDesktopNavigationOpaque,
  );

  const isDesktopNavigationShowing = useUIStore(
    (state) => state.isDesktopNavigationShowing,
  );

  // track whether this element has been registered in uiStore for isNavigationOpaque
  const isNavigationOpaque = useRef(false);

  // store last transform value to prevent unnecessary repeated value setting
  const lastHeaderWrapperTransform = useRef<string | null>(null);

  // TODO: split into measure and mutate?

  const transformHeaderWrapper = useCallback((value: string) => {
    if (
      value !== lastHeaderWrapperTransform.current ||
      lastHeaderWrapperTransform.current === null
    ) {
      if ($refTableHeadersWrapper.current) {
        $refTableHeadersWrapper.current.style.transform = value;
      }

      lastHeaderWrapperTransform.current = value;
    }
  }, []);

  const onTick = useCallback(() => {
    if (
      ScrollPosition.y !== null &&
      headingsBounds &&
      tableBounds &&
      windowHeight
    ) {
      const tableTopY = tableBounds.top - headingsBounds.height;
      const isInViewport =
        ScrollPosition.y + windowHeight >= tableTopY &&
        ScrollPosition.y <= tableBounds.bottom;

      if (isInViewport) {
        if (!isNavigationOpaque.current && $refEl.current) {
          isNavigationOpaque.current = true;
          setIsDesktopNavigationOpaque($refEl.current);
        }

        if (ScrollPosition.y >= tableTopY) {
          if (!isStuck) {
            setIsStuck(true);
          }
        }

        if ($refTableBodyWrapper.current) {
          const { top, height } =
            $refTableBodyWrapper.current.getBoundingClientRect();
          const bottomVisible = top + height <= windowHeight;
          if (bottomVisible && !ctaHidden) {
            setCtaHidden(true);
          } else if (!bottomVisible && ctaHidden) {
            setCtaHidden(false);
          }
        }

        if (isDesktopNavigationShowing) {
          if (ScrollPosition.y < tableTopY - navigationHeight) {
            // unstick complete
            transformHeaderWrapper('');
            setIsStuck(false);
          } else {
            // animate back into place
            const navYOffset = Math.round(
              Math.max(
                Math.min(
                  ScrollPosition.y -
                    (tableTopY - navigationHeight) -
                    navigationHeight,
                  navigationHeight,
                ) - 1,
                0,
              ),
            );

            transformHeaderWrapper(`translateY(${navYOffset}px)`);
          }
        } else {
          // navigation not showing, remove shift
          transformHeaderWrapper('');
        }
      } else {
        if (isNavigationOpaque.current && $refEl.current) {
          isNavigationOpaque.current = false;
          unsetIsDesktopNavigationOpaque($refEl.current);
        }
      }

      if ($refTableHeadersWrapper.current && $refTableBodyWrapper.current) {
        $refTableHeadersWrapper.current.scrollLeft =
          $refTableBodyWrapper.current.scrollLeft;
      }
    }
  }, [
    headingsBounds,
    isDesktopNavigationShowing,
    isStuck,
    navigationHeight,
    setIsDesktopNavigationOpaque,
    tableBounds,
    transformHeaderWrapper,
    unsetIsDesktopNavigationOpaque,
    windowHeight,
    ctaHidden,
  ]);

  useEffect(() => {
    const $el = $refEl.current;
    tickerAdd(onTick);

    return () => {
      tickerRemove(onTick);
      if ($el) {
        unsetIsDesktopNavigationOpaque($el);
      }
    };
  }, [
    headingsBounds,
    isStuck,
    onTick,
    tableBounds,
    unsetIsDesktopNavigationOpaque,
  ]);

  // recalculate bounds when window resizes
  useEffect(() => {
    if ($refHeadingsRow.current && $refTable.current) {
      setHeadingsBounds(getRootOffsetBounds($refHeadingsRow.current));
      setTableBounds(getRootOffsetBounds($refTable.current));
    }
  }, [windowWidth]);

  // for mobile table header + CTAs,
  //  set up sliders and manage their state
  const [headerSliderRef, headerSlider] = useKeenSlider({
    ...keenSliderConfig.defaultConfig,
    selector: '.headerSlide',
    initial: initialSelectedPlan.current,
    mode: 'snap',
    drag: true,
    dragSpeed: 0.01,
    slideChanged: (slider) => {
      const activeIndex = slider.track.details.rel;
      setSelectedPlan(products[activeIndex].slug);
    },
    slides: {
      perView: 'auto',
      spacing: 10,
      origin: 'center',
    },
  });

  const [ctaSliderRef, ctaSlider] = useKeenSlider({
    ...keenSliderConfig.defaultConfig,
    selector: '.ctaSlide',
    initial: initialSelectedPlan.current,
    drag: false,
    slides: {
      perView: 1,
      origin: 'center',
    },
  });

  // sync headers and CTA when table body updates
  useEffect(() => {
    if (headerSlider.current && ctaSlider.current && isMobile) {
      const updatedIndex = products.findIndex((product) => {
        return product.slug === selectedPlan;
      });

      if (updatedIndex > -1) {
        headerSlider.current.moveToIdx(updatedIndex);
        ctaSlider.current.moveToIdx(updatedIndex);
        setSelectedPlanIndex(updatedIndex);
      }
    }
  }, [
    selectedPlan,
    ctaSlider,
    headerSlider,
    products,
    getPlanCtaProps,
    isMobile,
  ]);

  return (
    <ModuleWrapper {...props} ref={$refEl} className={styles.wrapper}>
      <div className={styles.container} ref={$refTable} id="pricing-table">
        <div
          className={cn(
            styles.header,
            isDesktopNavigationShowing && styles.isDesktopNavigationShowing,
            isStuck && styles.isStuck,
          )}
          ref={$refTableHeadersWrapper}
        >
          <div className={styles.heading}>
            <TextLockup
              className={styles.headingLockup}
              value={text.blocks}
              lockupOptions={text.lockupOptions}
            />
          </div>
          <div className={styles.desktopPlanHeadersWrapper}>
            {products.map(({ tier, pricesByPeriod }) => {
              const ctaProps = getPlanCtaProps(pricesByPeriod);
              return (
                <div
                  key={tier.title}
                  className={styles.desktopPlanHeaders}
                  ref={$refHeadingsRow}
                >
                  <p className={styles.desktopHeaderTitle}>{tier.title}</p>
                  <p className={styles.desktopHeaderSubtitle}>
                    {renderPrice(pricesByPeriod)}
                  </p>
                  <Button
                    key={tier.title}
                    {...ctaProps}
                    buttonColorScheme="glass"
                    className={styles.pricingPlanButton}
                  >
                    {ctaProps?.to?.label}
                  </Button>
                </div>
              );
            })}
          </div>
          <div className={styles.planHeaders}>
            <div className={styles.planInner}>
              <ul className={styles.headerSlider} ref={headerSliderRef}>
                {products.map(({ slug, tier, pricesByPeriod }) => (
                  <li
                    key={tier.title}
                    className={cn('headerSlide', styles.headerSlide)}
                  >
                    <Button
                      buttonVariant="primary"
                      buttonColorScheme="glass"
                      className={cn(
                        styles.pricingPlanButton,
                        selectedPlan === slug && styles.selectedPlan,
                      )}
                      onClick={() => {
                        setSelectedPlan(slug);
                      }}
                    >
                      <span className={styles.pricingPlanTitle}>
                        {tier.title}
                      </span>{' '}
                      {renderPrice(pricesByPeriod)}
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.tableBody} ref={$refTableBodyWrapper}>
          <PricingSlider products={products} categories={pricingCategories} />
          <ClientOnlyPortal selector="body">
            <div
              className={cn(
                styles.stickyCtaContainer,
                isStuck && !ctaHidden && styles.sticky,
              )}
            >
              <Button
                {...getPlanCtaProps(products[selectedPlanIndex].pricesByPeriod)}
                buttonColorScheme="glass"
                buttonVariant="primary"
                className={styles.stickyCta}
              >
                <div ref={ctaSliderRef} className={cn(styles.stickyCtaSlider)}>
                  {products.map(({ pricesByPeriod }, index) => (
                    <span
                      key={index}
                      className={cn('ctaSlide', styles.ctaSlide)}
                      aria-hidden={index !== selectedPlanIndex}
                    >
                      {getPlanCtaProps(pricesByPeriod)?.to?.label}
                    </span>
                  ))}
                </div>
              </Button>
            </div>
          </ClientOnlyPortal>
          {pricingCategories.map((props) => (
            <PricingCategory
              key={props.title}
              {...props}
              className={styles.desktopCategory}
            />
          ))}
          {typeof disclaimer === 'string' && disclaimer.length > 0 && (
            <div className={styles.disclaimerContainer}>
              {disclaimer.split('\n').map((line) => (
                <div key={line} className={styles.disclaimer}>
                  {line}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default PricingTable;
