'use client';
import { gsap } from 'gsap';
import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import ButtonExpand from '~/components/atoms/Buttons/UI/ButtonExpand/ButtonExpand';
import Dim from '~/components/atoms/Dim/Dim';
import Image from '~/components/atoms/Image/Image';
import EnhancedMedia from '~/components/molecules/EnhancedMedia/EnhancedMedia';
import Glow from '~/components/molecules/Glow/Glow';
import PortableText from '~/components/molecules/PortableText/PortableText';
import Shadow from '~/components/molecules/Shadow/Shadow';
import { dict } from '~/data/stores/Dictionary';
import { cn } from '~/utils';

import styles from './CardItemSquare.module.css';
import { CardItemSquareProps, CardItemSquareRef } from './CardItemSquare.types';

const CardItemSquare = (
  { _key, className, content, image, title }: CardItemSquareProps,
  ref: ForwardedRef<CardItemSquareRef>,
) => {
  const $card = useRef<HTMLDivElement>(null);
  const textHeightSetter = useRef<(value: number) => void>();
  const $bodyWrapper = useRef<HTMLParagraphElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const renderClass = cn(
    styles.cardItemSquare,
    isOpen && styles.active,
    className,
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        collapseDetails: () => setIsOpen(false),
        $card,
      };
    },
    [],
  );

  useEffect(() => {
    if ($card.current) {
      textHeightSetter.current = gsap.quickSetter(
        $card.current,
        '--translate-offset',
        'px',
      ) as (value: number) => void;
    }
  }, []);

  useEffect(() => {
    if (textHeightSetter.current) {
      textHeightSetter.current(
        isOpen ? 0 - ($bodyWrapper.current?.offsetHeight ?? 0) : 0,
      );
    }
  }, [isOpen]);

  const Wrapper = image.glow ? Glow : 'div';

  return (
    <div ref={$card} className={renderClass}>
      <Wrapper source={image.glow}>
        <Shadow className={styles.shadowContainer}>
          <div className={styles.innerWrapper}>
            <button
              className={styles.buttonOverlay}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              aria-label={
                isOpen
                  ? `${dict('clickToClose')} ${title}`
                  : `${dict('clickToOpen')} on ${title}`
              }
              aria-controls={`${_key}-text`}
              aria-expanded={isOpen}
              key={_key}
            ></button>
            <div className={styles.textContainer} id={`${_key}-text`}>
              <div className={styles.titleWrapper}>
                <h4 className={styles.title}>{title}</h4>
              </div>
              <div className={styles.bodyWrapper} ref={$bodyWrapper}>
                <PortableText
                  aria-hidden={!isOpen}
                  value={content}
                  className={styles.bodyContent}
                  options={{
                    block: {
                      bodies: {
                        className: styles.bodies,
                      },
                    },
                    marks: {
                      // TODO: make `links` group (or alias) to cover all of these options
                      link: {
                        className: styles.link,
                      },
                      internalLink: {
                        className: styles.link,
                      },
                      mailTo: {
                        className: styles.link,
                      },
                      openConsent: {
                        className: styles.link,
                      },
                    },
                  }}
                />
              </div>
            </div>
            <EnhancedMedia
              overlay={image.overlay}
              className={styles.imageContainer}
              overlayClassName={styles.imageOverlay}
            >
              <Image source={image.image} />
            </EnhancedMedia>
            <Dim dim="heavy" className={styles.imageOverlayHover} />
            <ButtonExpand
              className={styles.buttonExpand}
              size="small"
              tag="div"
              open={isOpen}
            />
          </div>
        </Shadow>
      </Wrapper>
    </div>
  );
};

export default forwardRef<CardItemSquareRef, CardItemSquareProps>(
  CardItemSquare,
);
