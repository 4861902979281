/**
 * Button to toggle an element to be active/inactive, with label and icon
 * @param disabled Whether the button is diabled
 * @param icon The button's icon
 * @param iconPosition The icon's position, defaults to "after"
 * @param labelIdle The button's label when the element is inactive
 * @param labelActive The button's label when the element is active
 * @param isActive If the button is active, defaults to false
 * @param onClick Callback function for when the button is clicked
 * @param className
 * @param children
 * @example <ButtonToggle icon={<SvgCaret />} onClick={(isExpanded) => { setIsBulletsExpanded(isExpanded); }} className={styles.showMoreButton} labelIdle="Show Less" labelActive="Show More" />
 */
import { useState } from 'react';

import { EVENTS } from '~/analytics/config';
import getBaseTrackingData from '~/analytics/getBaseTrackingData';
import useTrackEventCallback from '~/analytics/hooks/useTrackEventCallback';
import { cn } from '~/utils';

import styles from './ButtonToggle.module.css';
import { ButtonToggleProps } from './ButtonToggle.types';

const ButtonToggle = ({
  disabled = false,
  icon,
  iconPosition = 'after',
  labelIdle = 'Off',
  labelActive = 'On',
  isActive = false,
  onClick,
  children,
  className,
}: ButtonToggleProps) => {
  const [isActiveState, setIsActiveState] = useState(isActive);

  const renderClasses = cn(
    styles.buttonToggle,
    disabled && styles.isDisabled,
    isActiveState && styles.isActive,
    iconPosition === 'before' && styles.isIconBefore,
    iconPosition === 'after' && styles.isIconAfter,
    className,
  );

  const trackedOnClick = useTrackEventCallback(EVENTS.buttonClick);

  const iconElement =
    typeof icon === 'undefined' ? null : typeof icon === 'string' ? (
      <figure
        className={styles.icon}
        dangerouslySetInnerHTML={{ __html: icon || '' }}
      />
    ) : (
      <figure className={styles.icon}>{icon}</figure>
    );

  const innerContent = (
    <>
      {iconPosition === 'before' && iconElement}
      <span className={styles.labelClasses}>
        <span className={styles.labelActive}>{labelActive}</span>
        <span className={styles.labelIdle}>{labelIdle}</span>
        {children}
      </span>
      {iconPosition === 'after' && iconElement}
    </>
  );

  return (
    <button
      className={renderClasses}
      onClick={() => {
        trackedOnClick({
          ...getBaseTrackingData(),
        });
        const willBeExpanded = !isActiveState;
        setIsActiveState(willBeExpanded);
        typeof onClick !== 'undefined' && onClick(willBeExpanded);
      }}
    >
      {innerContent}
    </button>
  );
};

export default ButtonToggle;
