'use client';

import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import { cn } from '~/utils';

import SideBySideItem from './SideBySideItem/SideBySideItem';
import styles from './SideBySides.module.css';
import { SideBySidesProps } from './SideBySides.types';
import SideBySideStack from './SideBySideStack/SideBySideStack';

const SideBySides = (props: SideBySidesProps) => {
  const { className, isStack, sideBySides, stackedSideBySides } = props;

  if (isStack) {
    return (
      <SideBySideStack
        className={cn(styles.sideBySides, className)}
        stackedSideBySides={stackedSideBySides}
      />
    );
  }

  return (
    <ModuleWrapper className={cn(styles.sideBySides, className)} {...props}>
      {sideBySides.map((value) => {
        return (
          <SideBySideItem
            className={styles.sideBySideItem}
            key={value._key}
            {...value}
          />
        );
      })}
    </ModuleWrapper>
  );
};

export default SideBySides;
