'use client';
import { gsap } from 'gsap';
import {
  ForwardedRef,
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import ButtonExpand from '~/components/atoms/Buttons/UI/ButtonExpand/ButtonExpand';
import Dim from '~/components/atoms/Dim/Dim';
import Graphic from '~/components/atoms/Graphic/Graphic';
import Image from '~/components/atoms/Image/Image';
import EnhancedMedia from '~/components/molecules/EnhancedMedia/EnhancedMedia';
import Glow from '~/components/molecules/Glow/Glow';
import PortableText from '~/components/molecules/PortableText/PortableText';
import Shadow from '~/components/molecules/Shadow/Shadow';
import { dict } from '~/data/stores/Dictionary';
import { cn } from '~/utils';

import styles from './CardItemLandscape.module.css';
import {
  CardItemLandscapeProps,
  CardItemLandscapeRef,
} from './CardItemLandscape.types';

const CardItemLandscape = (
  {
    _key,
    className,
    content,
    eyebrow = '',
    image,
    logo,
    logoWrapperHeight,
    title,
  }: CardItemLandscapeProps,
  ref: ForwardedRef<CardItemLandscapeRef>,
) => {
  const $card = useRef<HTMLDivElement>(null);
  const textHeightSetter = useRef<(value: number) => void>();
  const $bodyWrapper = useRef<HTMLParagraphElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const renderClass = cn(
    styles.cardItemLandscape,
    isOpen && styles.active,
    className,
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        collapseDetails: () => setIsOpen(false),
        $card,
      };
    },
    [],
  );

  useEffect(() => {
    if ($card.current) {
      textHeightSetter.current = gsap.quickSetter(
        $card.current,
        '--translate-offset',
        'px',
      ) as (value: number) => void;
    }
  }, []);

  useEffect(() => {
    if (textHeightSetter.current) {
      textHeightSetter.current(
        isOpen ? 0 - ($bodyWrapper.current?.offsetHeight ?? 0) : 0,
      );
    }
  }, [isOpen]);
  const Wrapper = image.glow ? Glow : 'div';

  return (
    <div ref={$card} className={renderClass}>
      <Wrapper source={image.glow}>
        <Shadow className={styles.shadowContainer}>
          <div className={styles.innerWrapper}>
            <button
              className={styles.buttonOverlay}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              aria-label={
                isOpen
                  ? `${dict('clickToClose')} ${title}`
                  : `${dict('clickToOpen')} ${title}`
              }
              aria-controls={`${_key}-text`}
              aria-expanded={isOpen}
              key={_key}
            ></button>
            {eyebrow && <div className={styles.eyebrow}>{eyebrow}</div>}
            <div className={styles.textContainer} id={`${_key}-text`}>
              <div className={styles.titleWrapper}>
                {logo && logoWrapperHeight && (
                  <div
                    className={styles.logoWrapper}
                    style={{
                      height: logoWrapperHeight
                        ? `${logoWrapperHeight / 10}rem`
                        : 'auto',
                    }}
                  >
                    <Graphic {...logo} className={styles.logo} />
                  </div>
                )}
                <h4 className={styles.title}>
                  {title.split('\n').map((titleText, index) => (
                    <Fragment key={`title_${index}`}>
                      {titleText.indexOf('•') >= 0 ? (
                        titleText.split('•').map((bulletText, bulletIndex) => (
                          <Fragment key={`bulletText_${bulletIndex}`}>
                            {bulletText}
                            {bulletIndex % 2 === 0 && (
                              <figure className={styles.bullet}>•</figure>
                            )}
                          </Fragment>
                        ))
                      ) : (
                        <Fragment key={`titleInner_${index}`}>
                          {titleText}
                        </Fragment>
                      )}
                      <br />
                    </Fragment>
                  ))}
                </h4>
              </div>
              <div className={styles.bodyWrapper} ref={$bodyWrapper}>
                <PortableText
                  aria-hidden={!isOpen}
                  value={content}
                  className={styles.bodyContent}
                  options={{
                    block: {
                      bodies: {
                        className: styles.bodies,
                      },
                    },
                    marks: {
                      // TODO: make `links` group (or alias) to cover all of these options
                      link: {
                        className: styles.link,
                      },
                      internalLink: {
                        className: styles.link,
                      },
                      mailTo: {
                        className: styles.link,
                      },
                      openConsent: {
                        className: styles.link,
                      },
                    },
                  }}
                />
              </div>
            </div>
            <EnhancedMedia
              overlay={image.overlay}
              className={styles.imageContainer}
              overlayClassName={styles.imageOverlay}
            >
              <Image source={image.image} className={styles.image} />
            </EnhancedMedia>
            <Dim dim="dim80" className={styles.imageOverlayHover} />
            <ButtonExpand
              className={styles.buttonExpand}
              size="small"
              tag="div"
              open={isOpen}
            />
          </div>
        </Shadow>
      </Wrapper>
    </div>
  );
};

export default forwardRef<CardItemLandscapeRef, CardItemLandscapeProps>(
  CardItemLandscape,
);
