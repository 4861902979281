'use client';
import { gsap } from 'gsap';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useRef } from 'react';

import { CMSImage } from '~/components/atoms/Image/Image.types';
import { VideoRef } from '~/components/atoms/Video/Video.types';
import Media from '~/components/molecules/Media/Media';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import useUIStore from '~/state/ui';
import { cn, getUniqueId, useScrollProgress } from '~/utils';

import styles from './OrganicMosaic.module.css';
import {
  COLUMN_PARALLAX_AMOUNT,
  ITEM_PARALLAX_AMOUNT,
  OrganicMosaicProps,
} from './OrganicMosaic.types';

/**
 * Organic Mosaic: displays  media cards in a column layout
 */
const OrganicMosaic = (props: OrganicMosaicProps) => {
  const { sortedItemsMobile, sortedItemsDesktop } = props;

  const $wrapper = useRef<HTMLDivElement>(null);
  const $element = useRef<HTMLDivElement>(null);

  const $columnsDesktop = useRef<HTMLElement[]>(
    Array(sortedItemsDesktop.length),
  );
  const $itemsDesktop = useRef<HTMLElement[][] | VideoRef[][]>(
    Array(sortedItemsDesktop.length),
  );

  const $columnsMobile = useRef<HTMLElement[]>(Array(sortedItemsMobile.length));
  const $itemsMobile = useRef<HTMLElement[][]>(Array(sortedItemsMobile.length));

  const $columnRefs = useRef<HTMLElement[]>();
  const $itemRefs = useRef<HTMLElement[][] | VideoRef[][]>();

  const breakpoint = useUIStore((state) => state.breakpoint);

  const updateTopMargin = useCallback(() => {
    const isDesktop = breakpoint?.name !== 'sm';
    if (isDesktop) {
      const parallaxAmountInPx =
        $columnRefs.current &&
        $columnRefs.current[0].getBoundingClientRect().height *
          (COLUMN_PARALLAX_AMOUNT / 100);
      gsap.set($wrapper.current, {
        '--margin-with-parallax': `${parallaxAmountInPx}px`,
      });
    } else {
      gsap.set($wrapper.current, {
        '--margin-with-parallax': 0,
      });
    }
  }, [breakpoint]);

  useEffect(() => {
    const unsubscribe = useUIStore.subscribe(
      (state) => [state.windowWidth as number],
      debounce(updateTopMargin, 100),
    );

    return () => {
      unsubscribe();
    };
  }, [updateTopMargin]);

  const onProgress = useCallback(
    (progress: number) => {
      const isDesktop = breakpoint?.name !== 'sm';
      if (!isDesktop) return;
      if ($columnRefs.current) {
        for (
          let colIndex = 0;
          colIndex < $columnRefs.current?.length;
          colIndex++
        ) {
          const columnY = gsap.utils.mapRange(
            0,
            1,
            COLUMN_PARALLAX_AMOUNT,
            -COLUMN_PARALLAX_AMOUNT,
            progress,
          );

          gsap.to($columnRefs.current[colIndex], {
            duration: (colIndex + 1) * 0.4,
            yPercent: columnY,
            ease: 'expo.out',
          });
        }
      }

      if ($itemRefs.current) {
        for (
          let colItemsIndex = 0;
          colItemsIndex < $itemRefs.current?.length;
          colItemsIndex++
        ) {
          for (
            let itemIndex = 0;
            itemIndex < $itemRefs.current?.[colItemsIndex].length;
            itemIndex++
          ) {
            const colItems = $itemRefs.current[colItemsIndex];

            const itemY = gsap.utils.mapRange(
              0,
              1,
              ITEM_PARALLAX_AMOUNT,
              -ITEM_PARALLAX_AMOUNT,
              progress,
            );
            if (colItems[itemIndex]) {
              // using px value here to ensure that the vertical gap between the items is consistent
              gsap.to(colItems[itemIndex], {
                duration: 0.75 * itemIndex,
                y: itemY,
                ease: 'expo.out',
              });
            }
          }
        }
      }
    },
    [breakpoint],
  );

  useEffect(() => {
    const isDesktop = breakpoint?.name !== 'sm';
    $columnRefs.current = isDesktop
      ? $columnsDesktop.current
      : $columnsMobile.current;
    $itemRefs.current = isDesktop
      ? $itemsDesktop.current
      : $itemsMobile.current;

    // set the initial parallax position of the tiles
    onProgress(0);
  }, [breakpoint, onProgress]);

  useScrollProgress($wrapper, onProgress);

  const renderItems = (whichBreakpoint: string) => {
    const items =
      whichBreakpoint === 'desktop' ? sortedItemsDesktop : sortedItemsMobile;

    const itemRefs =
      whichBreakpoint === 'desktop'
        ? $itemsDesktop.current
        : $itemsMobile.current;
    const columns =
      whichBreakpoint === 'desktop'
        ? $columnsDesktop.current
        : $columnsMobile.current;

    return (
      <div className={styles.mosaicContainer}>
        {items.map((colItems, i) => {
          itemRefs[i] = [];
          return (
            <div
              className={styles.column}
              key={`col-${getUniqueId()}`}
              ref={(element) => {
                if (element) {
                  columns[i] = element;
                }
              }}
            >
              {colItems.map((item, j) => {
                const thumbnailSrc =
                  item.media.sanityMedia.mediaType === 'video'
                    ? item.media.sanityMedia.thumbnail
                    : item.media.sanityMedia;

                return (
                  <Media
                    key={`${item._key}`}
                    // sm will use the static thumbnail of the video
                    sanityMedia={
                      whichBreakpoint === 'desktop'
                        ? item.media.sanityMedia
                        : (thumbnailSrc as CMSImage)
                    }
                    className={styles.item}
                    ref={(element) => {
                      if (element && itemRefs) {
                        const videoEl = element as VideoRef;
                        const el = videoEl.$wrapper?.current || element;

                        itemRefs[i][j] = el;
                      }
                    }}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <ModuleWrapper {...props} className={styles.mosaic} ref={$wrapper}>
      <div className={cn(styles.container, styles.itemsDesktop)} ref={$element}>
        {renderItems('desktop')}
      </div>
      <div className={cn(styles.container, styles.itemsMobile)}>
        {renderItems('mobile')}
      </div>
    </ModuleWrapper>
  );
};

export default OrganicMosaic;
