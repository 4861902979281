'use client';
import { ForwardedRef, forwardRef } from 'react';

import Image from '~/components/atoms/Image/Image';
import { CMSImage } from '~/components/atoms/Image/Image.types';
import { CMSVideoProps, VideoRef } from '~/components/atoms/Video/Video.types';
import AutoplayingVideo from '~/components/molecules/AutoplayingVideo/AutoplayingVideo';

import { MediaProps } from './Media.types';

const Media = (
  props: MediaProps,
  ref: ForwardedRef<HTMLElement | VideoRef>,
) => {
  const {
    ariaHidden,
    forceIsInView,
    forceUseMp4,
    className,
    contain,
    onReady,
    onVideoReady,
    onVideoEnded,
    playEndlessly,
    preload = false,
    sanityMedia,
    willAutoplay,
    imageAspectRatio,
    imageColumns,
    imageAnimated = true,
    quality,
    isCover,
  } = props;
  if (sanityMedia?.mediaType === 'image') {
    const imageRef = ref as ForwardedRef<HTMLImageElement>;
    const imageProps = sanityMedia as CMSImage;

    return (
      <Image
        source={imageProps}
        className={className}
        ref={imageRef}
        onReady={onReady}
        preload={preload}
        aspectRatio={imageAspectRatio}
        columns={imageColumns}
        animated={imageAnimated}
        isCover={isCover}
        quality={quality}
      />
    );
  }

  if (sanityMedia?.mediaType === 'video') {
    const videoRef = ref as ForwardedRef<VideoRef>;
    const videoProps = sanityMedia as CMSVideoProps;
    return (
      <AutoplayingVideo
        ariaHidden={ariaHidden}
        alt={videoProps.alt}
        ref={videoRef}
        aspectRatio={videoProps.aspectRatio}
        src={forceUseMp4 ? videoProps.mp4Url : videoProps.url}
        forceUseMp4={forceUseMp4}
        isLooping={videoProps.isLooping}
        forceIsInView={forceIsInView}
        className={className}
        onReady={onReady}
        onVideoReady={onVideoReady}
        onEnded={onVideoEnded}
        playEndlessly={playEndlessly}
        contain={contain}
        thumbnail={videoProps.thumbnail}
        willAutoplay={willAutoplay}
        imageAspectRatio={imageAspectRatio}
        imageColumns={imageColumns}
      />
    );
  }

  console.warn('Media Error: No valid Image or Video.', props);
  return null;
};

export default forwardRef(Media);
