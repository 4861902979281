import { pricingPlans } from '@frameio-bs/tokens';
import { useKeenSlider } from 'keen-slider/react';
import { useCallback, useEffect } from 'react';

import usePricingStore from '~/components/organisms/modules/Pricing/Pricing.store';
import { cn, keenSliderConfig } from '~/utils';

import {
  CMSPricingProduct,
  PricingCategory as PricingCategoryType,
} from '../Pricing.types';
import PricingCategory from './PricingCategory/PricingCategory';
import styles from './PricingTable.module.css';

export default function PricingSlider({
  products,
  categories,
}: {
  products: CMSPricingProduct[];
  categories: PricingCategoryType[];
}) {
  const [selectedPlan, setSelectedPlan] = usePricingStore((state) => [
    state.selectedPlan,
    state.setSelectedPlan,
  ]);

  const [sliderRef, slider] = useKeenSlider({
    ...keenSliderConfig.defaultConfig,
    selector: '.slide',
    slideChanged: (slider) => {
      const activeIndex = slider.track.details.rel;
      const activePlan = products[activeIndex].slug;
      setSelectedPlan(activePlan);
    },
    optionsChanged: () => {
      updateSlideHeight();
    },
    breakpoints: {
      [keenSliderConfig.breakpoints.md.mediaQuery]: {
        disabled: true,
      },
    },
  });

  const updateSlideHeight = useCallback(() => {
    setTimeout(() => {
      if (slider.current && slider.current?.track?.details?.rel) {
        const currentSlide =
          slider.current.slides[slider.current.track.details.rel];

        let innerHeight = 0;
        Array.from(currentSlide.children).forEach((element) => {
          innerHeight += element.clientHeight;
        });

        slider.current.container.style.height = `${innerHeight}px`;
      }
    }, 500);
  }, [slider]);

  useEffect(() => {
    const selectedIndex = Object.keys(pricingPlans).findIndex(
      (slug) => slug === selectedPlan,
    );
    if (slider.current && selectedIndex > -1) {
      slider.current.moveToIdx(selectedIndex);
      updateSlideHeight();
    }
  }, [selectedPlan, slider, updateSlideHeight]);

  return (
    <ul ref={sliderRef} className={cn(styles.sliderContainer)}>
      {products.map((product, index) => (
        <li key={index} className={cn('slide', styles.slide)}>
          {categories.map((props) => (
            <PricingCategory
              key={props.title}
              {...props}
              plan={product.slug}
              onToggle={updateSlideHeight}
            />
          ))}
        </li>
      ))}
    </ul>
  );
}
