'use client';

import Media from '~/components/molecules/Media/Media';
import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import { cn } from '~/utils';

import styles from './SocialProofCard.module.css';
import { SocialProofCardProps } from './SocialProofCard.types';

const SocialProofCard = ({
  content,
  backgroundMedia,
  row,
  bentoSize,
}: SocialProofCardProps) => {
  return (
    <div
      className={cn(
        styles.bentoCard,
        styles[`bentoSize${bentoSize}`],
        // when bento span over multiple rows, make sure the text align with the edge
        row > 1 && styles.spaceBetween,
      )}
    >
      <TextLockup
        value={content.blocks}
        lockupOptions={content.lockupOptions}
        className={styles.content}
      />
      {backgroundMedia && (
        <div className={styles.backgroundWrapper}>
          <Media
            sanityMedia={backgroundMedia.media.sanityMedia}
            className={styles.backgroundMedia}
          />
        </div>
      )}
    </div>
  );
};

export default SocialProofCard;
