'use client';
import { ForwardedRef, forwardRef, useImperativeHandle, useRef } from 'react';

import Link from '~/components/atoms/Link/Link';
import { VideoRef } from '~/components/atoms/Video/Video.types';
import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import Touts from '~/components/molecules/Touts/Touts';
import { cn } from '~/utils';

import MultiImageMediaContainer from './MultiImageMediaContainer/MultiImageMediaContainer';
import styles from './SideBySideItem.module.css';
import { SideBySideItemProps, SideBySideItemRef } from './SideBySideItem.types';
import SingleImageMediaContainer from './SingleImageMediaContainer/SingleImageMediaContainer';

const SideBySideItem = (
  props: SideBySideItemProps,
  ref: ForwardedRef<SideBySideItemRef>,
) => {
  const {
    className,
    innerClassNames,
    useEditorialLayout,
    content,
    mediaLayout,
    medias,
    title,
    touts,
    layout,
    logo,
    shouldParallax,
    $wrapper,
    links,
  } = props;

  const mediaRef = useRef<HTMLElement | VideoRef>(null);
  const $element = useRef<HTMLDivElement>(null);
  const isMultiImage = medias.length > 1 && mediaLayout === 'multiImage';

  useImperativeHandle(
    ref,
    () => ({
      mediaRef,
    }),
    [],
  );

  return (
    <div
      className={cn(
        styles.sideBySide,
        layout === 'mediaLeftTextRight'
          ? styles.isTextRight
          : styles.isTextLeft,
        mediaLayout === 'bleed' && styles.isBleed,
        mediaLayout === 'stackCard' && styles.isStackCard,
        className,
        isMultiImage && styles.isMultiImage,
        !content?.blocks?.length &&
          !title?.blocks?.length &&
          styles.noTitleAndContent,
      )}
      ref={$element}
    >
      <div
        className={cn(styles.contentWrapper, innerClassNames?.contentWrapper)}
      >
        {(title || content) && (
          <>
            {!!title?.blocks?.length && (
              <TextLockup
                lockupOptions={title.lockupOptions}
                className={cn(styles.content, styles.titleContent)}
                value={title.blocks}
              />
            )}
            {!useEditorialLayout && !!content?.blocks?.length && (
              <TextLockup
                lockupOptions={content.lockupOptions}
                className={cn(styles.content)}
                value={content.blocks}
              />
            )}
          </>
        )}
        {!useEditorialLayout && touts && (
          <Touts
            className={cn(styles.touts, styles.isTablet)}
            containerClassName={styles.toutsContainer}
            {...touts}
            isColumnLayout={true}
          />
        )}
        {!useEditorialLayout && (links as [])?.length > 0 && (
          <ol className={styles.linkList}>
            {links?.map((link, i) => (
              <li key={i} className={styles.linkListItem}>
                <Link to={link} className={styles.linkListItemLink}>
                  <span className={styles.linkListItemLabel}>{link.label}</span>
                </Link>
              </li>
            ))}
          </ol>
        )}
      </div>
      {/* Multiple images (previously "SideBySideV4") */}
      {isMultiImage ? (
        <MultiImageMediaContainer
          medias={medias}
          alignment={
            layout === 'mediaLeftTextRight' ? 'alignRight' : 'alignLeft'
          }
        />
      ) : (
        <>
          {/* Single image (updated version of the original "sideBySide") */}
          <SingleImageMediaContainer
            className={cn(
              styles.mediaContainer,
              innerClassNames?.mediaContainer,
            )}
            innerClassNames={{
              mediaWrapper: innerClassNames?.mediaWrapper,
              media: innerClassNames?.media,
            }}
            alignment={
              layout === 'mediaLeftTextRight' ? 'alignRight' : 'alignLeft'
            }
            media={medias[0]}
            logo={logo}
            mediaLayout={mediaLayout}
            $scrollWrapper={$wrapper || $element}
            shouldParallax={mediaLayout !== 'bleed' && shouldParallax}
          />
        </>
      )}
      {touts && (
        <div
          className={cn(
            styles.contentWrapper,
            innerClassNames?.contentWrapper,
            styles.isMobile,
          )}
        >
          <Touts
            className={styles.touts}
            containerClassName={styles.toutsContainer}
            {...touts}
            isColumnLayout={true}
          />
        </div>
      )}
    </div>
  );
};

export default forwardRef<SideBySideItemRef, SideBySideItemProps>(
  SideBySideItem,
);
