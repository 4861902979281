'use client';
import { gsap } from 'gsap';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

import Image from '~/components/atoms/Image/Image';
import Glow from '~/components/molecules/Glow/Glow';
import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import { ForwardedTextLockupRef } from '~/components/molecules/TextLockups/TextLockups.types';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import {
  cn,
  useIsomorphicLayoutEffect as useLayoutEffect,
  useScrollProgress,
} from '~/utils';
import AnimationTracker from '~/utils/singletons/AnimationTracker';
import { EaseType } from '~/utils/singletons/Easing';

import styles from './HeroImageWall.module.css';
import { HeroImageWallProps } from './HeroImageWall.types';

const HeroImageWall = (props: HeroImageWallProps) => {
  const { content, images, className } = props;
  const [shouldGenerateGlows, setShouldGenerateGlows] = useState(false);
  const hasAnimated = useRef(false);
  const $element = useRef<HTMLDivElement>(null);
  const $content = useRef<ForwardedTextLockupRef>(null);
  const $wall = useRef<HTMLDivElement>(null);
  const $imagesWrapper = useRef<HTMLDivElement>(null);

  const $images = useRef(Array(images.length));

  const progressSetter = useRef<ReturnType<typeof gsap.quickSetter>>();

  const lightAnimation = useCallback(
    ({
      $columnRefs,
      shouldWaitForFontLoad = true,
      paused = true,
      amplitude = 1,
    }: {
      $columnRefs: RefObject<HTMLDivElement[]>;
      shouldWaitForFontLoad?: boolean;
      paused?: boolean;
      amplitude?: number;
    }) => {
      const tl = gsap.timeline({
        paused,
        onComplete: () => {
          setShouldGenerateGlows(true);
        },
      });

      if (amplitude) {
        tl.timeScale(1 / amplitude);
      }

      const updateTimeline = () => {
        if ($columnRefs.current && $columnRefs.current.length > 0) {
          tl.fromTo(
            $columnRefs.current,
            {
              y: 100 * amplitude,
              opacity: 0,
            },
            {
              duration: 0.7,
              delay: 0.06,
              y: 0,
              ease: EaseType.DEFAULT,
              stagger: 0.07,
            },
            0,
          );
          tl.fromTo(
            $columnRefs.current,
            {
              opacity: 0,
            },
            {
              duration: 0.5,
              opacity: 1,
              stagger: 0.07,
            },
            // seconds past the start of the previous animation
            '<+=0.1',
          );
        }
      };

      if (shouldWaitForFontLoad) {
        document.fonts.ready.then(updateTimeline);
      } else {
        updateTimeline();
      }

      return tl;
    },
    [],
  );

  useEffect(() => {
    if ($element.current && $content.current?.$element?.current) {
      const markAnimationComplete = AnimationTracker.addPendingAnimation();
      hasAnimated.current = true;
      const tl = gsap.timeline({ delay: 1, onComplete: markAnimationComplete });
      tl.add(
        lightAnimation({
          $columnRefs: { current: [$content.current?.$element?.current] },
          shouldWaitForFontLoad: false,
          paused: false,
          amplitude: 1.7,
        }),
      );
      tl.add('image animation', 0);
      tl.to(
        $imagesWrapper.current,
        {
          '--entry-animation-progress': 1,
          duration: 1.4,
          ease: EaseType.DEFAULT,
        },
        'image animation',
      );
      tl.to(
        $element.current,
        {
          '--entry-animation-opacity': 1,
          duration: 0.3,
        },
        'image animation',
      );
      return markAnimationComplete;
    }
  }, [lightAnimation]);

  useLayoutEffect(() => {
    progressSetter.current = gsap.quickSetter(
      $wall.current,
      '--image-wall-progress',
    );
  }, []);

  const onProgress = useCallback(
    (progress: number) => {
      if (progressSetter.current) {
        let formattedProgress = progress;
        if (props.sectionIndex !== 0 || props.moduleIndex !== 0) {
          // If it's not the first module, we remap the progress from -1 to 1 so the state when
          // the wall is in the middle of the viewport is the comp position
          formattedProgress = gsap.utils.mapRange(0, 1, -1, 1)(progress);
        }
        progressSetter.current(Math.round(formattedProgress * 1000) / 1000);
      }
    },
    [props.sectionIndex, props.moduleIndex],
  );

  useScrollProgress($element, onProgress);

  $images.current = [];

  return (
    <ModuleWrapper
      className={cn(styles.heroImageWall, className)}
      ref={$element}
      {...props}
    >
      <div className={styles.contentWrapper}>
        <TextLockup
          ref={$content}
          value={content.blocks}
          className={styles.content}
          lockupOptions={content.lockupOptions}
        />
        <div className={styles.wall} ref={$wall}>
          <div className={styles.imagesWrapper} ref={$imagesWrapper}>
            {images.map((image, i) => {
              if (!image) return;
              return (
                <div
                  key={image._key}
                  className={styles.imageWrapper}
                  ref={(node) => ($images.current[i] = node)}
                >
                  <div className={styles.imageWrapperInner}>
                    <Image
                      columns={{
                        md: 3,
                        sm: 4,
                      }}
                      className={styles.image}
                      source={image.image}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className={styles.glows}>
            {images.map((image) => {
              if (!image) return;

              return (
                <Glow
                  key={image._key}
                  className={cn(styles.imageWrapper, styles.glow)}
                  source={
                    image.glow && {
                      ...image.glow,
                    }
                  }
                  shouldGenerate={shouldGenerateGlows}
                />
              );
            })}
          </div>
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default HeroImageWall;
