'use client';
import { gsap } from 'gsap';
import { capitalize } from 'lodash';
import { CSSProperties, useCallback, useRef } from 'react';

import GlassWrapper from '~/components/atoms/GlassWrapper/GlassWrapper';
import { VideoRef } from '~/components/atoms/Video/Video.types';
import Media from '~/components/molecules/Media/Media';
import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import useUIStore from '~/state/ui';
import {
  cn,
  useIsomorphicLayoutEffect as useLayoutEffect,
  useScrollProgress,
} from '~/utils';
import { EaseType } from '~/utils/singletons/Easing';

import {
  mediaSlideInAnimation,
  scaleDownAnimation,
} from './HeroScreen.animations';
import styles from './HeroScreen.module.css';
import { HeroScreenProps } from './HeroScreen.types';

/**
 * Hero Screen component
 * @param content Portable text content
 * @param media The main media element displayed on the device screen.
 * @param uiMedia The secondary media element displayed on the device screen (displays ui screen)
 * @param moduleIndex The module's index within its section
 * @param className
 * @example <HeroScreen media={media} uiMedia={uiMedia} content={content}/>
 */
const HeroScreen = (props: HeroScreenProps) => {
  const { content, media, uiMedia, moduleIndex, className, mediaSize } = props;
  const $element = useRef<HTMLDivElement>(null);
  const $media = useRef<VideoRef>(null);
  const $uiMedia = useRef<VideoRef>(null);
  const animation = useRef<GSAPTimeline>();
  const $mediaContainer = useRef<HTMLDivElement>(null);
  const $uiContainerInner = useRef<HTMLDivElement>(null);
  const $mediaWrapper = useRef<HTMLDivElement>(null);
  const progressSetter = useRef<(value: number) => void>();
  const opacityProgressSetter = useRef<(value: number) => void>();

  const initialMediaOpacity = useRef<string>(moduleIndex === 0 ? '0' : '');
  const bannerHeight = useUIStore((state) => state.bannerHeight);
  const bannerDismissed = useUIStore((state) => state.bannerDismissed);

  const onProgress = useCallback((progress: number) => {
    if (progressSetter.current)
      progressSetter.current(
        gsap.utils.clamp(0, 1, gsap.utils.normalize(0.1, 1, progress)),
      );

    if (opacityProgressSetter.current) {
      const opacityNormalizedProgress = gsap.utils.clamp(
        0,
        1,
        gsap.utils.normalize(0.4, 1, progress),
      );
      opacityProgressSetter.current(1 - opacityNormalizedProgress);
    }
    if (progress > 0.26) {
      // Scale down medias container
      animation.current?.play();
    }
  }, []);

  const onCompleteScaleDown = () => {
    // We play the UI video, if it exists
    $uiMedia.current?.play();
  };

  useScrollProgress($mediaContainer, onProgress);

  useLayoutEffect(() => {
    if (uiMedia?.sanityMedia?.mediaType && $uiContainerInner.current) {
      animation.current = scaleDownAnimation({
        $uiContainerInner,
        onCompleteScaleDown,
      });
    }
    if ($mediaWrapper.current) {
      mediaSlideInAnimation({
        $mediaWrapper,
      });
    }

    progressSetter.current = gsap.quickSetter(
      $element.current,
      '--transition-out-progress',
    ) as (value: number) => void;

    opacityProgressSetter.current = gsap.quickSetter(
      $element.current,
      '--opacity-progress',
    ) as (value: number) => void;
  }, [uiMedia?.sanityMedia?.mediaType]);

  //remove space on top of the hero that was accomodating announcement banner (only affects sm breakpoint visually)
  useLayoutEffect(() => {
    gsap.to($element.current, {
      duration: bannerDismissed ? 0.5 : 0,
      '--banner-height': bannerDismissed ? 0 : bannerHeight + 'px',
      ease: EaseType.BASIC_BUTTER,
    });
  }, [bannerHeight, bannerDismissed]);

  return (
    <ModuleWrapper
      className={cn(styles.heroScreen, className)}
      ref={$element}
      {...props}
    >
      <div className={styles.heroInner}>
        <TextLockup
          value={content.blocks}
          className={styles.content}
          lockupOptions={content.lockupOptions}
        />

        <div
          className={styles.mediaWrapper}
          ref={$mediaWrapper}
          style={
            initialMediaOpacity.current
              ? ({
                  opacity: initialMediaOpacity.current,
                } as CSSProperties)
              : {}
          }
        >
          <div
            className={cn(
              styles.mediaContainer,
              styles[`mediaContainer${capitalize(mediaSize)}`],
            )}
            ref={$mediaContainer}
          >
            <GlassWrapper className={cn(styles.uiContainer)}>
              <div
                className={cn(
                  styles.uiContainerInner,
                  styles[`mediaSize${capitalize(mediaSize)}`],
                )}
                ref={$uiContainerInner}
              >
                <Media
                  className={cn(styles.media)}
                  sanityMedia={media.media.sanityMedia}
                  ref={$media}
                  forceIsInView={true}
                  imageColumns={{
                    md: 8,
                    sm: 4,
                  }}
                />
              </div>
            </GlassWrapper>
          </div>
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default HeroScreen;
