'use client';

import { gsap } from 'gsap';
import { useCallback, useEffect, useRef } from 'react';
import { useStore } from 'zustand';

import getImageUrl from '~/components/atoms/Image/utils/getImageUrl';
import { useScrollProgress } from '~/utils';
import WindowSize from '~/utils/domEvents/WindowSize';

import { DebugUI } from './DebugUI/DebugUI';
import { Checkbox } from './DebugUI/inputs/Checkbox';
import { DebugUIGroup } from './DebugUI/inputs/DebugUIGroup';
import { NumericSlider } from './DebugUI/inputs/NumericSlider';
import styles from './HeroGradient.module.css';
import { ShaderParameterStore } from './HeroGradient.store';
import { CANVAS_SCALE_FACTOR, type HeroGradient } from './HeroGradient.types';

export const HeroGradientBackground = (props: HeroGradient) => {
  const media = props.media.media;
  const $container = useRef<HTMLDivElement>(null);
  const worker = useRef<Worker | null>(null);

  // NOTE: Because effects run twice in development we are not able to use a
  // canvas element by reference here as it will fail to transfer control the
  // second time the effect is invoked. We create the canvas here so we can
  // dipose of it properly in the cleanup function.
  useEffect(() => {
    if (!$container.current) {
      return;
    }

    worker.current = new Worker(
      new URL('./HeroGradient.worker.ts', import.meta.url),
    );

    const rect = $container.current.getBoundingClientRect();
    const width = Math.ceil(rect.width * CANVAS_SCALE_FACTOR);
    const height = Math.ceil(rect.height * CANVAS_SCALE_FACTOR);
    const sampleImageURL =
      media.sanityMedia.mediaType === 'image'
        ? getImageUrl(media.sanityMedia, {
            width: Math.ceil(width * 0.25),
            height: Math.ceil(height * 0.25),
          })
        : '';
    const canvas = document.createElement('canvas');
    const offscreen = canvas.transferControlToOffscreen();

    canvas.classList.add(styles.canvas);
    $container.current.appendChild(canvas);

    worker.current.postMessage(
      {
        type: 'init',
        sampleImageURL,
        canvas: offscreen,
        width,
        height,
      },
      [offscreen],
    );

    return () => {
      canvas.parentNode?.removeChild(canvas);
      worker.current?.terminate();
    };
  }, [media, worker]);

  // Find the closest <section> component and track the mouse movements on it
  useEffect(() => {
    if (!$container.current || !worker.current) {
      return;
    }

    const section = $container.current.closest('section');
    if (!section) {
      return;
    }

    const onSectionMouseMove = (evt: MouseEvent) =>
      worker.current?.postMessage({
        type: 'mousemove',
        x: evt.pageX,
        y: evt.pageY,
      });

    const onSectionTouch = (evt: TouchEvent) =>
      worker.current?.postMessage({
        type: 'mousemove',
        x: evt.changedTouches[0].pageX,
        y: evt.changedTouches[0].pageY,
      });

    section.addEventListener('touchstart', onSectionTouch);
    section.addEventListener('touchmove', onSectionTouch);
    section.addEventListener('mousemove', onSectionMouseMove);

    return () => {
      section.removeEventListener('touchstart', onSectionTouch);
      section.removeEventListener('touchmove', onSectionTouch);
      section.removeEventListener('mousemove', onSectionMouseMove);
    };
  }, [$container, worker]);

  // Resize the canvas based on the container size
  useEffect(
    () =>
      WindowSize.subscribe(() => {
        if (!$container.current || !worker.current) {
          return;
        }
        const rect = $container.current.getBoundingClientRect();
        worker.current.postMessage({
          type: 'resize',
          width: Math.ceil(rect.width * CANVAS_SCALE_FACTOR),
          height: Math.ceil(rect.height * CANVAS_SCALE_FACTOR),
        });
      }),
    [$container, worker],
  );

  // Manage playing & pausing the renderer when in/out of view
  useScrollProgress(
    $container,
    (progress: number, isInView: boolean) => {
      gsap.to($container.current, {
        y: `${Math.round(progress * 50)}%`,
        duration: 0.5,
        ease: 'expo.out',
        overwrite: true,
      });

      if (!worker.current) {
        return;
      }

      if (isInView) {
        worker.current.postMessage({ type: 'play' });
      } else {
        worker.current.postMessage({ type: 'pause' });
      }
    },
    {
      startOnMiddleOfScreen: false,
      finishOnMiddleOfScreen: false,
    },
  );

  const store = useStore(ShaderParameterStore);

  useEffect(() => {
    if (!props.debugUI) {
      return;
    }
    // if we have a shared params object, save it to local storage
    try {
      const url = new URL(location.href);
      const params = url.searchParams.get('params');
      if (params) {
        const data = JSON.parse(decodeURIComponent(params));
        localStorage.setItem('falkor.hero-shader', JSON.stringify(data));
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    if (!props.debugUI) {
      return;
    }

    const unsubFromStore = ShaderParameterStore.subscribe(async (state) => {
      localStorage.setItem('falkor.hero-shader', JSON.stringify(state));
      const nodes =
        $container.current?.parentElement?.querySelectorAll('article');
      if (nodes) {
        nodes.forEach((node) => {
          node.style.visibility = state.showModules ? 'visible' : 'hidden';
        });
      }
      if (worker.current) {
        worker.current.postMessage({
          type: 'shader-parameters',
          params: {
            randomSeed: state.randomSeed,
            brushSize: state.brushSize,
            numOctaves: state.numOctaves,
            mouseDeltaScale: state.mouseDeltaScale,
            densityDissipation: state.densityDissipation,
            pressureDissipation: state.pressureDissipation,
            velocityDissipation: state.velocityDissipation,
            curlStrength: state.curlStrength,
          },
        });
      }
    });

    // grab the persisted data from local storage and apply it to the
    // store. if we had a shared URL the shared data should now have
    // overwritten the store
    try {
      const value = localStorage.getItem('falkor.hero-shader');
      if (!value) {
        return;
      }
      ShaderParameterStore.setState(JSON.parse(value));
    } catch (err) {
      console.error(err);
    }

    return () => {
      unsubFromStore();
    };
  }, [props.debugUI]);

  const resetShaderParameters = useCallback(() => {
    localStorage.removeItem('falkor.hero-shader');
    window.location.reload();
  }, []);

  const shareURL = useCallback(() => {
    const data = encodeURIComponent(JSON.stringify(store));
    const url = new URL(location.href);
    url.searchParams.set('params', data);
    if (navigator.clipboard) {
      navigator.clipboard.writeText(url.toString());
    }
  }, [store]);

  return (
    <div className={styles.heroGradient} ref={$container}>
      {props.debugUI && (
        <DebugUI onReset={resetShaderParameters} onShare={shareURL}>
          <DebugUIGroup name="UI">
            <Checkbox
              id="toggle-ui"
              label="Show Modules"
              value={store.showModules}
              onChange={store.setShowModules}
            />
          </DebugUIGroup>
          <DebugUIGroup name="Aurora">
            <NumericSlider
              id="random-seed"
              label="Random Seed"
              min={1}
              max={100_000}
              value={store.randomSeed}
              onChange={store.setRandomSeed}
            />
            <NumericSlider
              id="num-octaves"
              label="Noise Octaves"
              min={1}
              max={10}
              step={1}
              value={store.numOctaves}
              onChange={store.setNumOctaves}
            />
          </DebugUIGroup>
          <DebugUIGroup name="Interaction">
            <NumericSlider
              id="brush-size"
              label="Brush Size"
              min={0.1}
              max={0.5}
              value={store.brushSize}
              onChange={store.setBrushSize}
            />
            <NumericSlider
              id="mouse-delta-scale"
              label="Mouse Delta Scale"
              min={0.1}
              max={0.9}
              value={store.mouseDeltaScale}
              onChange={store.setMouseDeltaScale}
            />
          </DebugUIGroup>
          <DebugUIGroup name="Fluid Dynamics">
            <NumericSlider
              id="curl-strength"
              label="Curl Strength"
              min={1}
              max={100}
              value={store.curlStrength}
              onChange={store.setCurlStrength}
            />
            <NumericSlider
              id="velocity"
              label="Velcity Dissipation"
              min={0.9}
              max={1}
              value={store.velocityDissipation}
              onChange={store.setVelocityDissipation}
            />
            <NumericSlider
              id="pressure"
              label="Pressure Dissipation"
              min={0.1}
              max={1}
              value={store.pressureDissipation}
              onChange={store.setPressureDissipation}
            />
            <NumericSlider
              id="density"
              label="Density Dissipation"
              min={0.9}
              max={1}
              value={store.densityDissipation}
              onChange={store.setDensityDissipation}
            />
          </DebugUIGroup>
        </DebugUI>
      )}
    </div>
  );
};
