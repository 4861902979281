'use client';

import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import { cn } from '~/utils';

import Media from '../../Media/Media';
import styles from './StatsCard.module.css';
import { StatsCardProps } from './StatsCard.types';

const StatsCard = ({ content, blurredBackground, media }: StatsCardProps) => {
  return (
    <div
      className={cn(
        styles.statsCard,
        blurredBackground && styles.blurredBackground,
      )}
    >
      {media && (
        <Media
          sanityMedia={media.media.sanityMedia}
          className={styles.backgroundImage}
        />
      )}
      <TextLockup
        value={content.blocks}
        lockupOptions={content.lockupOptions}
        className={styles.content}
      />
    </div>
  );
};

export default StatsCard;
